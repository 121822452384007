import { CreateImpactReportSchedulingType, ImpactReportSchedulingType, ModifyImpactReportSchedulingType } from 'types/impactReportScheduling.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReportSchedulings = async () => {
  return await api.get('impact-report-scheduling');
};

export const getImpactReportSchedulingById = async (id: string): Promise<ImpactReportSchedulingType> => {
  return await api
    .get(`impact-report-scheduling/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportScheduling = async (ids: string) => {
  return await api.delete(`impact-report-scheduling/${ids}`);
};

export const deleteImpactReportSchedulingBulk = async (ids: string[]) => {
  return await api.deleteWithParams('impact-report-scheduling', { UIds: ids });
};

export const createImpactReportScheduling = async (impactReportScheduling: CreateImpactReportSchedulingType) => {
  return await api.post('impact-report-scheduling', impactReportScheduling);
};

export const modifyImpactReportScheduling = async (id: string, impactReportScheduling: ModifyImpactReportSchedulingType) => {
  return await api.put(`impact-report-scheduling/${id}`, impactReportScheduling);
};

export const searchImpactReportSchedulings = async (searchTerm: string) => {
  return await api.get(`impact-report-scheduling/lookup/${searchTerm}`);
};

export const generateAllForLastMonth = async () => {
  return await api.post('impact-report-scheduling/generateAllForLastMonth');
};

export const generateAllForPeriod = async (dateFrom: Date, dateTo: Date) => {
  return await api.post('impact-report-scheduling/generateAllForPeriod?dateFrom', { dateFrom: dateFrom, dateTo: dateTo });
};

