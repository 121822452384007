import React from 'react';

interface TileProps {
  label: string;
  value: string;
}

const TileStr: React.FC<TileProps> = ({ label, value }) => {
  const tileStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
    width: '130px',
    height: '50px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    margin: '5px',
    padding: '10px',
    fontFamily: 'Arial, sans-serif',
  };

  const labelStyle = {
    fontSize: '14px',
    color: '#555',
  };

  const valueStyle = {
    fontSize: '16px',
    fontWeight: 'bold' as const,
    color: '#333',
    alignSelf: 'center',
  };

  return (
    <div style={tileStyle}>
      <div style={labelStyle}>{label}</div>
      <div style={valueStyle}>{value}</div>
    </div>
  );
};

export default TileStr;
