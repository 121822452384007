import { Button, Card, Checkbox, Container, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getImpactReportSchedulingTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { handleClick, handleSelectAllClick, sortByColumn, } from 'services/list.service';

import { endOfDay, lastDayOfMonth, lastDayOfYear, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { deleteImpactReportScheduling, deleteImpactReportSchedulingBulk, generateAllForLastMonth, generateAllForPeriod, getImpactReportSchedulings, searchImpactReportSchedulings } from 'services/impactReportScheduling.services';
import { ImpactReportSchedulingType } from 'types/impactReportScheduling.type';

export default function impactReportSchedulingPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [impactReportSchedulingList, setImpactReportSchedulingList] = useState<ImpactReportSchedulingType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getImpactReportSchedulingsHandler();
  }, []);

  const getImpactReportSchedulingsHandler = () => {
    getImpactReportSchedulings().then((response) => {
      setImpactReportSchedulingList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchImpactReportSchedulings(filterName).then((response) => {
          setImpactReportSchedulingList(response.data);
        });
      } else {
        getImpactReportSchedulingsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteImpactReportScheduling = (impactReportSchedulingId: string) => {
    deleteImpactReportScheduling(impactReportSchedulingId)
      .then(() => {
        setImpactReportSchedulingList([...impactReportSchedulingList].filter(({ id }) => id !== impactReportSchedulingId));
        handleSelectAllClick(false, impactReportSchedulingList);
        setOpenModal(false);
        handleOpen(t('impactReportSchedulingPage.userDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteImpactReportSchedulingBulk = (impactReportSchedulingIds: string[]) => {
    deleteImpactReportSchedulingBulk(impactReportSchedulingIds)
      .then(() => {
        setImpactReportSchedulingList(impactReportSchedulingList.filter(({ id }) => !impactReportSchedulingIds.includes(id)));
        handleSelectAllClick(false, impactReportSchedulingList);
        setOpenModal(false);
        handleOpen(t('impactReportSchedulingPage.usersDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteImpactReportSchedulingAction = () => {
    if (selected.length > 1) {
      handleDeleteImpactReportSchedulingBulk(selected);
    } else {
      handleDeleteImpactReportScheduling(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const sortedImpactReportSchedulingList = sortByColumn(
    impactReportSchedulingList,
    orderBy as keyof ImpactReportSchedulingType,
    order,
  );

  const impactReportSchedulingTableHead = useMemo(getImpactReportSchedulingTableHead, [language]);

  const generateAllForThisMonth = () => {
    const dateFrom = startOfMonth((startOfDay(new Date())));
    const dateTo = lastDayOfMonth((endOfDay(new Date())));

    generateAllForPeriod(dateFrom, dateTo);
  };

  const generateAllForThisYear = () => {
    const dateFrom = startOfYear(startOfMonth((startOfDay(new Date()))));
    const dateTo = lastDayOfYear(lastDayOfMonth((endOfDay(new Date()))));

    generateAllForPeriod(dateFrom, dateTo);
  };

  return (
    <Container>
      <PageHeader title={t('impactReportSchedulingPage.title')} buttonTitle={t('impactReportSchedulingPage.buttonTitle').toString()} hasButton hasBackButton/>
      
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} onClick={() => generateAllForLastMonth()}>         {t('impactReportSchedulingPage.generateAllForLastMonthButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} onClick={() => generateAllForThisMonth()}>         {t('impactReportSchedulingPage.generateAllForThisMonthButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} onClick={() => generateAllForThisYear()}>         {t('impactReportSchedulingPage.generateAllForThisYearButtonTitle')}</Button>



      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={impactReportSchedulingTableHead}
                rowCount={impactReportSchedulingList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, impactReportSchedulingList))
                }
              />
            }
            <TableBody>
              {sortedImpactReportSchedulingList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,

                    name,
                    description,

                    //firstDayOfExecution,
                    //generationFrequency,

                    organisation,
                    model, 
                    template,
                    //emailtext,
                    //autosendemail
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >

                      <TableCell padding="checkbox"><Checkbox checked={isItemSelected}onClick={() => setSelected(handleClick(id, selected))}/></TableCell>

                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{description}</TableCell>

                      <TableCell align="left">{organisation.companyName}</TableCell>
                      <TableCell align="left">{template.name}</TableCell>
                      <TableCell align="left">{model.name}</TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
            {!impactReportSchedulingList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={impactReportSchedulingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteImpactReportSchedulingAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
